<script setup>
import { WALKER_BEFORE_DELIVERY_PAGE } from "../../../const/route";
import { useLmsStore } from "../../../stores/lms";
import InfoCard from "../../cards/InfoCard.vue";
import PageContainer from "../../layout/PageContainer.vue";

const store = useLmsStore();
</script>

<template>
    <PageContainer
        title="Welcome!"
        :next-path="WALKER_BEFORE_DELIVERY_PAGE"
        :show-back-button="false"
    >
        <InfoCard v-if="store.hasInduction">
            <p class="italic text-sm">You have completed this module.</p>
        </InfoCard>
        <div class="my-8 content-img">
            <img src="/walkers/welcome.jpeg" alt="" />
        </div>
        <p class="my-4 text-center">
            Welcome to REACH! The next few pages are here to start you off right while you&apos;re
            part of the REACH walker team. We&apos;ve got some cool tips on what to do (and what not
            to do), plus some must-know info to keep you safe and spot any tricky situations while
            you&apos;re out making those deliveries.
        </p>
        <p class="my-4 text-center">Let&apos;s dive in and set you up for success!</p>
    </PageContainer>
</template>
