<script setup>
const props = defineProps({
    iconFilename: { type: String, required: true },
    iconSize: { type: Number, default: 32 },
});
</script>

<template>
    <div class="p-4">
        <div class="flex flex-col sm:flex-row">
            <img
                :src="props.iconFilename"
                alt=""
                :class="`w-${props.iconSize} h-${props.iconSize} mr-4 self-center`"
            />
            <div class="self-center">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
